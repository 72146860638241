.lottery-content-wrapper {
  display: flex;

  .lottery-content {
    display: flex;
    flex: 1;
    text-align: center;
    &:not(:last-child) {
      margin-right: 30px;
    }

    &.southern {
      .result-item {
        .result-item-header {
          background-color: $southernColor;
        }

        .result-item-content {
          .result-ball .ball {
            color: $southernColor;
          }

          .result-info .action {
            color: $southernColor;
            &:after {
              border-left-color: $southernColor;
            }
          }

          .result-table .result-row .label {
            color: $southernColor;
          }
        }
      }
    }

    @import "result-item";

    .main-item-wrapper {
      margin-right: 15px;
      width: calc(50% - (15px / 2));
      .result-item {
        height: 100%;
      }
    }

    .big-wrapper {
      width: calc(50% - (15px / 2));
      .result-item {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
