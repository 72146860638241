.about {
  color: #686868;
  .about-container {
    .container {
      display: flex;
    }

    .img-wrapper {
      display: flex;
      align-items: flex-end;
      padding-top: 50px;
      width: calc(100% - 550px);
      img {
        max-width: 100%;
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      width: 550px;

      .title {
        color: $aboutColor;
        font-weight: 400;
      }
    }

    &.top {
      .img-wrapper {
        justify-content: flex-end;
        img {
          width: 90%;
        }
      }
      .text-wrapper {
        position: relative;
        &:before {
          content: '';
          background: url('../img/footer-left.png') center / contain no-repeat;
          height: 179px;
          width: 109px;
          position: absolute;
          bottom: 0;
          left: -139px;
        }
      }
    }

    &.bottom {
      background-color: $aboutColor;
      color: #fff;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    font-family: "Times New Roman", serif;
    height: 120px;
    text-align: left;

    .container {
      display: flex;
      align-items: center;
    }

    .sub-footer-text {
      font-size: 18px;
      margin-right: 45px;
      padding-right: 45px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 30px;
        width: 1px;
        background-color: #707070;
      }
    }

    .sub-footer-content {
      font-size: 14px;
    }
  }
}
