.dropdown-toggle {
  position: relative;
  cursor: pointer;
  &:before{
    content: '';
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(calc(-50% + 2px));
    border-style: solid;
    border-width: 4px;
    border-color: transparent;
    border-top-color: #fff;
  }
  &:hover {
    .dropdown {
      max-height: 400px;
      opacity: 1;
      visibility: visible;
    }
  }
  .dropdown {
    position: absolute;
    top: 32px;
    background-color: #fff;
    width: 160px;
    border-radius: 3px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 0;
    z-index: 100;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 400ms $materialCubicBezier;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
    &:before {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 8px;
      border-color: transparent;
      border-bottom-color: #fff;
      top: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
    .dropdown-title {
      font-weight: bold;
      text-align: center;
      height: 20px;
      display: flex;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      color: #686868;
      &:first-child {
        margin-top: 0;
      }
    }
    .dropdown-item {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      text-decoration: none;
      color: #686868;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
