.result-item {
  background-color: #FAFBFC;
  box-shadow: 0 0 3px #00000029;

  .result-item-header {
    background-color: $northernColor;
    color: #fff;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 12px;
    > .title {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
    .draw-time {
      color: rgba(255,255,255,.7);
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-left: auto;
      .time-counter{
        margin-left: 4px;
      }
    }
  }

  .result-item-content {
    .result-ball {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;

      .ball {
        background: url(../img/ball-bg.png) center / contain no-repeat;
        color: $northernColor;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        width: 38px;
      }
    }

    .result-info {
      color: #686868;
      display: flex;
      align-items: center;
      height: 33px;
      padding: 0 15px;

      .drawnumber {
        font-size: 12px;
      }

      .action {
        color: $northernColor;
        font-size: 14px;
        font-weight: 400;
        margin-left: auto;
        padding-right: 13px;
        position: relative;
        text-decoration: none;
        &:after {
          border: 6px solid transparent;
          border-left-color: $northernColor;
          border-right: 0;
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          transition: all 300ms $materialCubicBezier;
        }
        &:hover {
          &:after {
            transform: translate(5px,-50%);
          }
        }
      }
    }
    .result-table {
      .result-row {
        display: flex;
        align-items: center;
        height: 34px;
        padding: 0 16px;
        &:nth-child(odd) {
          background-color: rgba(227, 233, 239, .4);
        }
        &:last-child {
          border-bottom: 1px solid #f1edeb;
          .text {
            flex-basis: calc(100% / 5);
          }
        }
        .label {
          color: $northernColor;
          flex: 0 0 calc(100% / 4);
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 2px;
          text-align: center;
          sup {
            font-size: 12px;
            letter-spacing: 1px;
          }
        }
        .text {
          flex: 1 0 calc(100% / 4);
          font-size: 16px;
          text-align: center;
        }
        .special {
          color: red;
        }
      }
    }
  }
}
