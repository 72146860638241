@import "variables";
@import "header";
@import "lottey-content";
@import "about";
@import "modal";
@import "datepicker";
@import "countdown";

html,
body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", "microsoft yahei",
    sans-serif;
  background-color: #fff;
}

.pace .pace-progress {
  transition: all 100ms ease-in-out;
}

.pace-running {
  .body-wrapper {
    &:before {
      z-index: 10;
      content: "";
      position: fixed;
      right: 0;
      bottom: 0;
      margin: auto;
      //background: url(../img/loading.gif) center / contain no-repeat;
      width: 70px;
      height: 70px;
      opacity: 0.25;
      transition: all 500ms ease-in-out;
    }
  }
}

.body-wrapper {
  min-width: 1200px;
  transition: opacity 150ms ease-in;
  @media screen and (max-width: $maxWidth) {
    min-width: 100%;
  }
}

body.ready {
  &:before {
    display: none;
  }

  .body-wrapper {
    opacity: 1;
  }
}

body {
  &.modal-open {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.container {
  width: 1200px;
  margin: auto;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: $maxWidth) {
    width: 100%;
  }
}

.body-wrapper {
  min-height: 100vh;
  .content {
    padding: 20px 0;
    min-height: calc(100% - #{$headerHeight});
    @media screen and (max-width: $maxWidth) {
      padding: 80px 0 20px;
    }
  }
}

.flex {
  display: flex;
  > * {
    flex-grow: 1;
    flex-shrink: 1;
    &:not(:last-child) {
      border-right: 1px solid #f0eff0;
    }
  }
  .flex-10 {
    flex-basis: 10%;
  }
  .flex-20 {
    flex-basis: 20%;
  }
  .flex-30 {
    flex-basis: 30%;
  }
  .flex-40 {
    flex-basis: 40%;
  }
  .flex-50 {
    flex-basis: 50%;
  }
  .flex-60 {
    flex-basis: 60%;
  }
  .flex-70 {
    flex-basis: 70%;
  }
  .flex-80 {
    flex-basis: 80%;
  }
  .flex-90 {
    flex-basis: 90%;
  }
}

.is_stuck {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.ui-menu .ui-menu-item {
  font-size: 14px;
}
