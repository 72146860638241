.modal {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &.animate {
    animation: modalIn 400ms $materialCubicBezier forwards;
    .modal-wrapper {
      animation: modalInWrapper 400ms 200ms $materialCubicBezier forwards;
      opacity: 0;
    }
  }

  &.fade-out {
    .modal-wrapper {
      animation: modalOutWrapper 200ms $materialCubicBezier forwards;
    }
    animation: modalOut 200ms 200ms $materialCubicBezier forwards;
  }

  &.south {
    .modal-wrapper {
      .modal-header {
        background-color: $southernColor;
      }
      .modal-content {
        color: $southernColor;
        .main-content {
          .main-content-header {
            .date-picker-wrapper .icon-calendar {
              background-color: $southernColor;
            }
          }

          .result-wrapper {
            .result-table .result-row {
              &:first-child {
                .label,
                .text {
                  color: $southernColor;
                }
              }
            }
          }
        }

        .sidebar {
          .sidebar-title {
            background-color: $southernColor;
          }
          .sidebar-content {
            .sidebar-lottery-item {
              &.active {
                color: $southernColor;
              }
              &:hover {
                color: $southernColor;
              }
            }
          }
        }
      }
    }
  }

  .modal-wrapper {
    border-radius: 15px;
    height: 620px;
    width: 900px;
    position: fixed;
    z-index: 101;

    .modal-header {
      background-color: $northernColor;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      position: relative;

      .modal-title {
        margin: 0;
        font-weight: 300;
        font-size: 18px;
        text-align: center;
        text-transform: capitalize;
      }

      .close {
        border: 0;
        background: transparent;
        cursor: pointer;
        font-size: 0;
        height: 40px;
        width: 40px;
        outline: none;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 30px;
          height: 2px;
          background-color: #fff;
          transform: rotate(45deg);
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 30px;
          height: 2px;
          background-color: #fff;
          transform: rotate(-45deg);
        }
      }
    }

    .modal-content {
      color: $northernColor;
      display: flex;
      height: calc(100% - 40px);

      .main-content {
        background-color: #fff;
        height: 100%;
        overflow: auto;
        width: calc(100% - 188px);

        .main-content-header {
          display: flex;
          align-items: center;
          padding: 20px 60px 10px;
          .lottery-name-wrapper {
            display: flex;
            align-items: center;
            img {
              margin-right: 8px;
              width: 40px;
            }
          }
          .date-picker-wrapper {
            display: flex;
            align-items: center;
            margin-left: auto;
            .icon-calendar {
              background-color: $northernColor;
              height: 24px;
              width: 24px;
              margin-left: 8px;
              mask: url("../img/calendar.svg") center / contain no-repeat;
              -webkit-mask: url("../img/calendar.svg") center / contain
                no-repeat;
            }
          }
        }

        .result-wrapper {
          display: flex;
          padding: 0 60px;

          .result-table {
            flex-basis: 60%;

            &.head-tail {
              flex-basis: 40%;
              margin-left: 30px;

              .result-row {
                &:first-child {
                  .text {
                    letter-spacing: 0;
                  }
                }
                .label {
                  color: #686868;
                  font-family: unset;
                  font-size: 16px;
                  font-style: unset;
                }
                .text {
                  letter-spacing: 1px;
                  span {
                    flex: unset;
                  }
                }
              }
            }

            .result-row {
              display: flex;
              align-items: center;
              height: 40px;
              padding: 0 16px;
              &:nth-child(even) {
                background-color: #f1f4f8;
              }
              &:first-child {
                .label,
                .text {
                  color: $northernColor;
                  font-size: 14px;
                }
              }
              &:last-child {
                .text {
                  flex: 1 0 calc(100% / 5);
                }
              }

              .label {
                flex: 0 0 calc(100% / 4);
                font-size: 12px;
                text-align: center;
              }

              .text {
                color: #686868;
                display: inline-flex;
                justify-content: center;
                flex: 1 0 calc(100% / 4);
                flex-wrap: wrap;
                font-size: 16px;
                text-align: center;
                &.drawnumber {
                  justify-content: flex-start;
                  text-align: left;
                }
                span {
                  flex: 1;
                  letter-spacing: 2px;
                  &.special {
                    color: red;
                  }
                }
              }
            }
          }
        }
      }

      .sidebar {
        background-color: #f1f1f1;
        height: 100%;
        width: 188px;
        .sidebar-title {
          background-color: $northernColor;
          border-top: 1px solid rgba(255, 255, 255, 0.8);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 300;
          height: 40px;
          @import "dropdown";
        }
        .countdown {
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          .countdown-label {
            font-size: 14px;
            margin-right: 5px;
          }
        }
        .sidebar-content {
          height: calc(100% - 40px - 40px);
          overflow: auto;

          .sidebar-lottery-item {
            border-bottom: 1px solid rgba(255, 255, 255, 0.8);
            color: #686868;
            display: flex;
            align-items: center;
            font-size: 14px;
            height: 40px;
            padding-left: 30px;
            text-decoration: none;
            &.active {
              background-color: rgba(255, 255, 255, 0.4);
              color: $northernColor;
            }
            &:hover {
              background-color: rgba(255, 255, 255, 0.1);
              color: $northernColor;
            }
          }
        }
      }
    }
  }
}

@keyframes modalIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalInWrapper {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes modalOutWrapper {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(1.1);
  }
}
