.countdown-timer {
  display: flex;
  align-items: center;
  height: 28px;
  overflow: hidden;
  &.dark {
    .countdown-timer-item {
      background: rgba(255, 255, 255, 0.8);
      margin: 0 2px;
      width: 17px;
    }
  }
  .countdown-timer-item {
    border-radius: 4px;
    overflow: hidden;
    width: 12px;
    height: 100%;
    position: relative;
    .digit-wrapper {
      position: absolute;
      width: 100%;
      transition: all 500ms $materialCubicBezier;
      transform: translateY(-90%);
      > span {
        //height: 1em;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }
    }
  }
}
