.header {
  .container {
    text-align: center;
    position: relative;
  }

  .language-selector {
    position: absolute;
    right: 0;
    top: 20px;

    select#languageSelector {
      padding: 5px;
      background: #fafbfc;

      border: 0;
      border-radius: 0;
      box-shadow: 0 0 3px #00000029;

      font-family: inherit;
      font-size: 16px;
    }
  }

  .top-header {
    background: url("../img/header-bg.png") top center no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 370px;
    margin-bottom: 30px;

    .top-header-wrapper {
      font-weight: bold;
      margin: 0 50px;
      width: 120px;
      word-wrap: break-word;
      &.northern {
        color: $northernColor;
      }
      &.southern {
        color: $southernColor;
      }

      .today-date {
        font-weight: 400;
        margin-top: 8px;
        min-height: 19px;
      }
    }
  }

  .lottery-selector {
    display: flex;
    align-items: center;

    .lottery-item {
      flex: 1 1 50%;
      position: relative;

      &.northern {
        color: $northernColor;
        padding-left: 15px;
        padding-right: 30px;
        text-align: left;
        .lottery-item-wrapper a div {
          color: $northernColor;
        }
      }

      &.southern {
        color: $southernColor;
        padding-left: 30px;
        padding-right: 15px;
        text-align: right;
        .lottery-item-wrapper a div {
          color: $southernColor;
        }
      }

      .lottery-item-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        > a {
          color: currentColor;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          height: 80px;
          width: 80px;
          position: relative;
          text-align: center;
          transition: all 200ms $materialCubicBezier;

          &:not(:hover) {
            color: #ced8e2;
          }

          > div {
            font-size: 24px;
            font-weight: bold;
            line-height: 16px;
            &.time-unit {
              font-size: 16px;
              font-weight: 500;
            }
          }

          > svg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}
